<template>
  <div id="app">
    <div class="container">
      <h1>Human Verification</h1>
      <vue-slide-verify
        :l="42"
        :r="24"
        :w="350"
        :h="150"
        @success="onSuccess"
        @fail="onFail"
        @refresh="onRefresh"
      ></vue-slide-verify>
      <div v-if="isVerified" class="success-message">
        <p>Verification successful! Redirecting...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueSlideVerify from 'vue-slide-verify';

export default {
  components: {
    VueSlideVerify
  },
  data() {
    return {
      isVerified: false,
      redirectUrl: ''
    };
  },
  methods: {
    onSuccess() {
      this.isVerified = true;
      this.fetchAndRedirect();
    },
    onFail() {
      this.isVerified = false;
    },
    onRefresh() {
      this.isVerified = false;
    },
    async fetchAndRedirect() {
      try {
        const response = await axios.get('https://www.nice-app.vip/api/third/address');
        // console.log("返回:",response);
        this.redirectUrl = response.data.data.url; // 假设返回的数据中有一个 `url` 字段
        window.location.href = this.redirectUrl;
      } catch (error) {
        console.error('获取地址失败:', error);
      }
    }
  }
};
</script>

<style>
/* 添加一些基本样式 */
#app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.success-message {
  margin-top: 20px;
  color: green;
}
</style>